<template>
    <div>
        <section v-if="dataLoading" class="about py-lg-9">
            <div class="text-center pt-7 pb-7">
                <loading-animation class="text-primary text-5x"/>
            </div>
        </section>
        <div v-else class="container font-inter-regular fs-norm align-justify">
            <div v-if="details">
                <div class="container py-6 font-inter-regular">
                    <base-breadcrumb
                        :path="[{name :'FAQ'}, {name :'Bank e Auctions India.com'}]"
                        class="mb-4"></base-breadcrumb>
                    <div>
                        <div v-for="(item, index) in details" :key="index">
                            <div class="ml-lg-7 ml-3 mt-5">
                                <h3 class="mb-4 font-inter-bold fs-lg-5 text-sm-left">{{ item.question }}</h3>
                                <p class="mb-4 text-justify" v-html="item.description"/>
                            </div>
                            <list-section-card v-for="(section, index2) in item.sections" :key="index2" color="primary"
                                               :heading="section.title"
                                               class="ml-lg-7 mr-3 w-80p-res">
                                <template #default>
                                    <p class="mb-4 text-justify" v-html="item.description"/>
                                </template>
                            </list-section-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseBreadcrumb from '../components/ui/BaseBreadcrumb';
import ListSectionCard from '@components/faq/ListSectionCard';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name: 'FaqPage',
    components: { ListSectionCard, BaseBreadcrumb },
    data () {
        return {
            details: '',
            dataLoading: false
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.FAQ);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped lang="scss">
.underline-item {
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -12px;
    left: 0;
    background-color: rgba(128, 128, 128, 0.7);
}
</style>
